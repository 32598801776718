<template>
  <div class="container-fluid" id="laptop">
    <div class="alert_cont">
      <b-alert
      :show="dismissCountDown"
      dismissible
      :variant="alertType"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged">{{ alertMessage }}</b-alert>
    </div>
    <form>
      <div class="form-row bgGray" id="exchangeDialog">
        <div class="col-sm-4">
          <div class="centered">
            <div class="group">
              <input type="text" id="currency" ref="currency" class="input_field" required="required" v-model="amount" @input="calc(amount)"/>
                <label for="currency">
                  <img class="flag-icon" v-if="this.active_currency.name != 'Валюта'" :src="img_path"> {{active_currency.name}}
                </label>
              <div class="bar"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 text-center">
          <div id="image">
            <nav class='menu'>
              <input checked='checked' class='menu-toggler' type='checkbox'>
              <label for='menu-toggler'></label>
              <ul>
                <li class='menu-item' v-for="currency in currencies" :value="currency.value" :key="currency.key" v-bind:class="setClass(currency.symbol)">
                  <a class='fa' href='#' @click="getRate(currency.name)">{{currency.symbol}}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="centered">
            <div class="group">
              <input type="text" id="hryvna" class="input_field" ref="hryvna" required="required"/>
              <label for="hryvna"><img class="flag-icon" :src="require(`images/uah.svg`)"> Гривна</label>
              <div class="bar"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row order_button_row">
      <div v-if="active_currency.name != 'Валюта'"><b-button v-b-modal.modal-center class="btn btn-primary btn-outline-white mb-2">Замовити валюту</b-button></div>
    </div>
    <b-modal id="modal-center" ref="orderModal" centered title="Оформити заказ" hide-footer>
      <div>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-1"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              placeholder="Введіть свій номер телефону"
              required
              autofocus
              :class="{'has_error': phone_error}"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3">
            <b-form-input
              id="uah_amount"
              v-model="form.amount"
              required
              placeholder="Cумма"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4">
            <b-form-checkbox v-model="form.action" name="check-button" switch>
              Я бажаю {{ form.action ? 'купити' : 'продати' }} {{ form.amount }} {{ active_currency.name }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group id="input-group-5">
            Актуальний курс {{ active_currency.name }}: <b>{{ active_currency.buy }} / {{ active_currency.sell }}</b>
          </b-form-group>

          <b-button type="submit" class="text-center button-primary mb-2 button-main-color">Відправити</b-button>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

  export default {
    name: 'Exchange',
    data () {
      return {
        exchangeVisible: true,
        currencies: [],
        active_currency: { name: 'Валюта', buy: 0.0, sell: 0.0 },
        amount: '',
        form: {
          phone: '',
          amount: '',
          currency: '',
          action: true
        },
        phone_error: false,
        dismissSecs: 5,
        dismissCountDown: 0,
        alertType: '',
        alertMessage: ''
      }
    },
    methods: {
      getRate(currency) {
        this.active_currency = this.currencies.filter(c => c['name'] == currency)[0]
        this.$refs.currency.focus()
        this.calc(this.amount);
      },
      calc(amount, event) {
        let calc = parseInt(this.active_currency.buy * amount)
        this.$refs.hryvna.value = calc
        this.form.amount = amount
      },
      setClass(symbol) {
        if (this.active_currency.symbol == symbol) {
          return 'active_tab'
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert(message, type) {
        this.alertType = type
        this.alertMessage = message
        this.dismissCountDown = this.dismissSecs
      },
      // Add amount error? TODO
      onSubmit(event) {
        event.preventDefault()
        this.form.currency = this.active_currency.name
        if (this.form.amount && this.form.phone.length >= 10 && 
            this.form.phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)) {
          axios.post('/orders', { order: this.form },
            { headers: { 'Accept': 'application/json' } }).then(
              (response)=> {
                this.$refs['orderModal'].hide()
                this.phone_error = false
                this.showAlert('Ваше замовлення успішно надіслано!', 'success');
              },
              (error) => {
                this.showAlert(error['response']['data']['phone'][0], 'danger')
              })
        }
        else {
          this.phone_error = true
        }
      },
      getCurrencies () {
        axios.get("/currencies/remote.json")
        .then(
          (response) => {
            this.currencies = response.data.data.map(x => x.attributes);
          },
          (error) => { console.log(error); }
        )
      }
    },
    computed: {
      img_path: function() {
        if(this.active_currency.flag) {
          return require('images/' + this.active_currency.flag + '.svg')
        } else {
          return ""
        }
      }
    },
    beforeMount() { this.getCurrencies() },
  }
</script>

<style type="text/css">

.flag-icon {
  height: 25px;
  width: 25px;
}

@media (max-width: 1000px) {
  .bar {
    width: 150px;
  }

  #laptop {
    display: none;
  }

  #flex-center {
    justify-content: center;
  }

  #customNav button {
    display: none;
  }

  header, #footer {
    font-size: 14px;
  }

  #social li p {
    font-size: 14px !important;
  }

  #footer img {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 645px) {
  #mobile {
    display: none;
  }
}

@media (max-width: 645px) {
  #laptop {
    display: none;
  }

  #buttons {
    padding-top: 1em!important;
  }

  #about_exchange {
    display: none;
  }

  #map_img {
    height: auto !important;
  }

  #laptop_container {
    display: none;
  }

  #mobile {
    display: inline-block;
    padding-top: 20px;
  }

  #logo {
    height: auto!important;
    width: 98%!important;
    padding: 0px!important;
    margin: 0px!important;
  }

  .navbar-brand {
    padding: 0px 0px 0px 0px!important;
    margin: 0px 0px 0px 0px!important;
  }

  #customNav {
    padding: 0!important;
    margin: 0!important;
  }

  header, #footer {
    font-size: 10px;
  }

  #social li p {
    font-size: 9px !important;
  }
}

@media (max-width: 460px) {
  #social li p, #footer {
    font-size: 7px !important;
  }

  #probootstrap-nav {
    display: none;
  }

  h1 {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  #social li p, #footer {
    font-size: 5px !important;
  }

  .sm-mobile {
    display: none;
  }
}

#image a:hover {
  text-decoration: none;
}

.bgGray {
  background-color: transparent;
}

.input_field {
  background-color: transparent;
}

#hryvna label {
  color: black!important;
}

#image {
  margin-top: 1em;
}

.calculator {
  border-radius: 10px;
}

.description {
  border-radius: 10px;
  width: 100%;
}

.alert_cont {
  position: absolute;
  top: 20px;
  left: 20px;
}

.has_error {
  border: 1px solid red!important;
}

.order_button_row {
  justify-content: center;
  margin-top: 5.5em;
}

#exchangeDialog {
  margin-top: 5em;
  height: 15em;
}

* {
  box-sizing: border-box;
}

.centered {
  width: 350px;
  height: 70px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.group {
  width: 100%;
  height: 70px;
  overflow: hidden;
  position: relative;
}

label {
  position: absolute;
  top: 18.3333333333px;
  color: black;
  font: 400 23.3333333333px;
  cursor: text;
  transition: .25s ease;
}

input {
  display: block;
  width: 100%;
  padding-top: 23.3333333333px;
  border: none;
  border-radius: 0;
  color: black;
  background: gray;
  font-size: 23.3333333333px;
  transition: .3s ease;
}
input:valid ~ label {
  top: 0;
  font: 700 14px;
}
input:focus {
  outline: none;
}
input:focus ~ label {
  top: 0;
  font: 700 14px;
  color: rgb(213, 25, 32);
}
input:focus ~ .bar:before {
  transform: translateX(0);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px gray inset;
  -webkit-text-fill-color: white !important;
}

.bar {
  background: black;
  content: '';
  width: 300px;
  height: 2.3333333333px;
  transition: .3s ease;
  position: relative;
}

.bar:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 150%;
  background: rgb(213, 25, 32, 0.9);
  transform: translateX(-100%);
}

::selection {
  background: gray;
}

.menu-toggler {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.menu-toggler:hover + label, .menu-toggler:hover + label:before, .menu-toggler:hover + label:after {
  background: transparent;
}

.menu-toggler:checked + label {
  background: transparent;
}
.menu-toggler:checked + label:before, .menu-toggler:checked + label:after {
  top: 0;
  width: 40px;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.menu-toggler:checked + label:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.menu-toggler:checked + label:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.menu-toggler:checked ~ ul .menu-item {
  opacity: 1;
  text-decoration: none;
}
.menu-toggler:checked ~ ul .menu-item:nth-child(1) {
  -webkit-transform: rotate(0deg) translateX(-110px);
          transform: rotate(0deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item:nth-child(2) {
  -webkit-transform: rotate(60deg) translateX(-110px);
          transform: rotate(60deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item:nth-child(3) {
  -webkit-transform: rotate(120deg) translateX(-110px);
          transform: rotate(120deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item:nth-child(4) {
  -webkit-transform: rotate(180deg) translateX(-110px);
          transform: rotate(180deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item:nth-child(5) {
  -webkit-transform: rotate(240deg) translateX(-110px);
          transform: rotate(240deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item:nth-child(6) {
  -webkit-transform: rotate(300deg) translateX(-110px);
          transform: rotate(300deg) translateX(-110px);
}
.menu-toggler:checked ~ ul .menu-item a {
  pointer-events: auto;
}

.menu-toggler + label {
  width: 40px;
  height: 5px;
  display: block;
  z-index: 1;
  border-radius: 2.5px;
  background: rgba(255, 0, 0, 0.8);
  /*
  transition: top 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, top 0.5s;
  transition: transform 0.5s, top 0.5s, -webkit-transform 0.5s;
  */
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.menu-toggler + label:before, .menu-toggler + label:after {
  width: 40px;
  height: 5px;
  display: none;
  z-index: 1;
  border-radius: 2.5px;
  background: rgba(255, 0, 0, 0.8);
  /*
  transition: top 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, top 0.5s;
  transition: transform 0.5s, top 0.5s, -webkit-transform 0.5s;
  */
  content: "";
  position: absolute;
  left: 0;
}
.menu-toggler + label:before {
  top: 10px;
}
.menu-toggler + label:after {
  top: -10px;
}

.menu-item:nth-child(1) a {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.menu-item:nth-child(2) a {
  -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
}

.menu-item:nth-child(3) a {
  -webkit-transform: rotate(-120deg);
          transform: rotate(-120deg);
}

.menu-item:nth-child(4) a {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.menu-item:nth-child(5) a {
  -webkit-transform: rotate(-240deg);
          transform: rotate(-240deg);
}

.menu-item:nth-child(6) a {
  -webkit-transform: rotate(-300deg);
          transform: rotate(-300deg);
}

.menu-item {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  display: block;
  opacity: 0;
  transition: 0.5s;
}

.menu-item a {
  display: block;
  width: inherit;
  height: inherit;
  line-height: 80px;
  color: white;
  background: rgba(255, 0, 0, 0.8);
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  font-size: 40px;
  pointer-events: none;
  transition: .2s;
}

.menu-item a:hover {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
  color: black;
  background: white;
  font-size: 44.4444444444px;
}

.active_tab a {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5);
  color: black;
  background: white;
}
</style>
