<template>
<div id="app">
  <div class="wrapper probootstrap-cover">
    <CurrencyRate :currencies="currencies"></CurrencyRate>
    <MainButtons></MainButtons>
    <transition name="fadeInUpBig" mode="out-in" enter-active-class="animated fadeInUpBig">
      <router-view/>
    </transition>
    <div class="basic_footer">
      <Footer></Footer>
    </div>
    <div class="mobile_footer">
      <MobileFooter :currencies="currencies"></MobileFooter>
    </div>
  </div>
</div>
</template>

<script>
import CurrencyRate from './CurrencyRate.vue'
import MainButtons from './MainButtons.vue'
import Credit from './Credit.vue'
import Exchange from './Exchange.vue'
import Footer from './Footer.vue'
import MobileFooter from './MobileFooter.vue'
import axios from 'axios'

export default {
  name: 'app',
  components: {
    CurrencyRate, MainButtons, Credit, Exchange, Footer, MobileFooter
  },
  data () {
    return {
      currencies: []
    }
  },
  methods: {
    prevent (event) {
      event.preventDefault()
      event.stopPropagation()
    },
    getCurrencies () {
      axios.get("/currencies/remote.json")
      .then(
        (response) => {
          this.currencies = response.data.data.map(x => x.attributes);
         },
        (error) => { console.log(error); }
      )
    }
  },
  beforeMount() { this.getCurrencies() }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css";

@media screen and (min-width: 768px) {
  .mobile_footer {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .basic_footer {
    display: none;
  }
}

* {
  margin: 0;
}

html {
  overflow-x: hidden;
  height: 100%; }

body {
  background: #fff;
  font-family: "Raleway", sans-serif;
  line-height: 1.7;
  color: black;
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

h1, h2, h3, h4, h5 {
  line-height: 1.5;
  font-weight: normal;
  color: #000; }

.navbar {
  position: relative !important;
}

.probootstrap-nav {
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2); }

.probootstrap-nav .site-brand {
  font-size: 20px; }
  .probootstrap-nav .site-brand a {
    color: #fff; }
.probootstrap-nav .probootstrap-toggle {
  color: #fff !important;
  position: absolute;
  z-index: 10;
  right: 20px;
  top: 20px; }
.probootstrap-nav .probootstrap-main-nav {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
.probootstrap-nav .probootstrap-main-nav ul {
  padding: 0;
  margin: 0;
}

.probootstrap-nav .probootstrap-main-nav ul li {
  display: inline;
  list-style: none;
}

.probootstrap-nav .probootstrap-main-nav ul li a {
  padding: 10px;
  color: rgba(213, 25, 32, 0.5);
}

.probootstrap-nav .probootstrap-main-nav ul li.seperator {
  position: relative;
}

.probootstrap-nav .probootstrap-main-nav ul li.seperator:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .probootstrap-nav .probootstrap-main-nav {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    height: 100vh;
    background: black;
    width: 300px;
    right: 0;
    top: 0;
    padding-top: 50px;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 1;
  }
}

.probootstrap-nav .probootstrap-main-nav.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.probootstrap-nav .probootstrap-main-nav ul li {
  display: block;
  padding: 10px;
}

.probootstrap-nav .probootstrap-main-nav ul li a:hover {
  color: #000;
}

.bg-image, .probootstrap-cover, .probootstrap-img-item > div, .probootstrap-section-feature {
  background-size: cover;
  background-repeat: no-repeat;
}

.probootstrap-cover {

  background-position: center center;
}

.probootstrap-cover .probootstrap-text .probootstrap-heading {
  font-size: 50px;
  line-height: 1;
}

.probootstrap-cover .probootstrap-text .probootstrap-subheading p {
  color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  .probootstrap-cover .btn {
    width: 100% !important;
    border: 1px solid red; }
}

.btn {
  border-radius: 40px;
  padding: 20px 30px;
  font-size: 14px;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn.btn-primary {
    background: rgb(213, 25, 32, 0.9);
    border-color: rgb(213, 25, 32, 0.9);
    color: #000; }
    .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
      background: rgb(213, 25, 32, 0.9); }
  .btn.btn-outline-white {
    border-color: #000;
    background: none; }
    .btn.btn-outline-white:hover, .btn.btn-outline-white:focus, .btn.btn-outline-white:active {
      background: #fff;
      color: #000; }

.form-control {
  border: 2px solid #ccc;
  height: 55px; }
  .form-control:focus, .form-control:active {
    border: 2px solid #000; }

textarea {
  height: inherit !important; }

.probootstrap-vh-100 {
  height: 100vh; }
  @media screen and (max-width: 768px) {
    .probootstrap-vh-100 {
      height: inherit;
      padding-top: 5em;
      padding-bottom: 5em; } }

.probootstrap-vh-75 {
  height: 75vh; }
  @media screen and (max-width: 768px) {
    .probootstrap-vh-75 {
      height: inherit;
      padding-top: 5em;
      padding-bottom: 5em; } }

.probootstrap-vh-20 {
  height: 20vh; }
  @media screen and (max-width: 768px) {
    .probootstrap-vh-20 {
      height: inherit;
      padding-top: 5em;
      padding-bottom: 5em; } }

.probootstrap-vh-5 {
  height: 5vh; }
  @media screen and (max-width: 768px) {
    .probootstrap-vh-5 {
      height: inherit;
      padding-top: 3em;
      padding-bottom: 3em; } }

.bg-primary {
  background: rgb(213, 25, 32) !important; }

.probootstrap-section,
.probootstrap-footer {
  padding: 7em 0; }

.probootstrap-footer {
  background: #000; }
  .probootstrap-footer a {
    color: #000; }
    .probootstrap-footer a:hover {
      color: rgb(213, 25, 32, 0.9); }
  .probootstrap-footer .probootstrap-heading {
    color: #000;
    font-size: 24px; }
  .probootstrap-footer .probootstrap-heading-2 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: .05em;
    color: #000;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase; }

.probootstrap-item {
  display: block; }

.probootstrap-image-grid > div {
  padding-left: 0;
  padding-right: 0; }
  .probootstrap-image-grid > div > div {
    padding-left: 0;
    padding-right: 0; }

.probootstrap-img-item {
  display: block;
  position: relative;
  overflow: hidden; }
  .probootstrap-img-item .icon {
    position: absolute;
    color: #000;
    z-index: 9;
    top: 50%;
    font-size: 30px;
    margin: 20px 0 0 0;
    padding: 0;
    line-height: 0;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
  .probootstrap-img-item > div {
    height: 280px;
    -webkit-transition: .5s all ease;
    -o-transition: .5s all ease;
    transition: .5s all ease;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
    @media screen and (max-width: 1140px) {
      .probootstrap-img-item > div {
        height: 200px; } }
    @media screen and (max-width: 576px) {
      .probootstrap-img-item > div {
        height: 280px; } }
    .probootstrap-img-item > div:before {
      -webkit-transition: .5s all ease;
      -o-transition: .5s all ease;
      transition: .5s all ease;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden; }
  .probootstrap-img-item:hover .icon {
    margin-top: 0;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible; }
  .probootstrap-img-item:hover > div {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
    .probootstrap-img-item:hover > div:before {
      opacity: 1;
      visibility: visible; }

.probootstrap-section-feature {

  background-position: center center; }
  .probootstrap-section-feature .probootstrap-heading {
    color: #000; }
  .probootstrap-section-feature .probootstrap-device {
    top: 0;
    position: absolute;
    max-width: 470px; }
    @media screen and (max-width: 768px) {
      .probootstrap-section-feature .probootstrap-device {
        position: relative;
        top: inherit;
        margin-bottom: -330px; } }
  .probootstrap-section-feature h3 {
    color: #000; }
  .probootstrap-section-feature p {
    color: rgba(255, 255, 255, 0.4); }

.probootstrap-navabr-dark {
  background: rgba(0, 0, 0, 0.2) !important;
  position: absolute;
  left: 0;
  right: 0; }
  @media screen and (max-width: 960px) {
    .probootstrap-navabr-dark {
      background: #000 !important;
      top: 0;
      position: relative; } }
  .probootstrap-navabr-dark .navbar-nav > .nav-item > .nav-link {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
    @media screen and (max-width: 960px) {
      .probootstrap-navabr-dark .navbar-nav > .nav-item > .nav-link {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0px;
        padding-right: 0px; } }
  .probootstrap-navabr-dark .navbar-nav > .nav-item.probootstrap-cta a {
    color: black; }
  .probootstrap-navabr-dark .navbar-nav > .nav-item.probootstrap-seperator {
    position: relative;
    margin-left: 20px;
    padding-left: 20px; }
    @media screen and (max-width: 960px) {
      .probootstrap-navabr-dark .navbar-nav > .nav-item.probootstrap-seperator {
        padding-left: 0;
        margin-left: 0; } }
    .probootstrap-navabr-dark .navbar-nav > .nav-item.probootstrap-seperator:before {
      position: absolute;
      content: "";
      top: 10px;
      bottom: 10px;
      left: 0;
      width: 2px;
      background: rgba(255, 255, 255, 0.05); }
      @media screen and (max-width: 960px) {
        .probootstrap-navabr-dark .navbar-nav > .nav-item.probootstrap-seperator:before {
          display: none; } }

.probootstrap-footer-social li {
  list-style: none;
  margin: 0 10px 10px 0;
  display: inline-block; }
  .probootstrap-footer-social li a {
    height: 70px;
    width: 70px;
    display: block;
    float: left;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    position: relative; }
    .probootstrap-footer-social li a span {
      position: absolute;
      font-size: 30px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.footer-small-nav > li {
  font-size: 13px;
  display: inline-block; }
  .footer-small-nav > li a {
    color: rgba(255, 255, 255, 0.4);
    margin: 0 10px 10px 0; }
    .footer-small-nav > li a:hover, .footer-small-nav > li a:focus {
      color: rgb(213, 25, 32, 0.9); }

.media .probootstrap-icon {
  width: 100px; }
  .media .probootstrap-icon span {
    color: rgb(213, 25, 32, 0.9); }

.probootstrap-overflow-hidden {
  overflow: hidden; }


.currency_rate {
  width: 100%;
  height: 3em;
  position: absolute;
}

.wrapper {
  height: 100vh;
}

.description_wrapper {
  padding-bottom: 5em;
  display: block;
}

.footer_icon {
  padding-right: 5px;
  font-size: 16px;
}

footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0.7
}

.footer a:hover {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}

.footer_our {
  clear:both;
  padding-top:20px;
}

.padding_top {
  padding-top: 3em !important;
}

.padding_top_after {
  padding-top: 1em !important;
}

.padding_about {
  padding-top: 3em;
}
</style>
