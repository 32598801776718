<template>
<section class="padding_top" id="buttons" v-bind:class="{ paddind_top: isVisible, padding_top_after: !isVisible }">
  <div class="container" id="laptop_container">
    <div class="row align-items-center text-center">
      <div class="col-sm" id="main_elements">
        <div class="probootstrap-text">
          <transition name="my-trans" mode="in-out" leave-active-class="animated lightSpeedOut faster" v-if="$route.name == 'MainButtons'">
            <h1 class="probootstrap-heading text-black mb-4" v-if="isVisible">Обмін валют у місті Покровськ</h1>
          </transition>
          <transition name="my-trans" mode="out-in" leave-active-class="animated lightSpeedOut faster" v-if="$route.name == 'MainButtons'">
            <div class="probootstrap-subheading mb-5" v-if="isVisible">
              <p class="h4 font-weight-normal">Найзручніший спосіб обміняти валюту</p>
            </div>
          </transition>
            <p>
              <a href="#" id="obmen" @click="isVisible = false" @mouseover="exchangeHover = true, creditHover = false"><router-link to="/exchange" class="btn btn-primary mr-2 mb-2 button-main-color">Обмін валют</router-link></a>
              <a href="#" id="credit" @click="isVisible = false"><router-link to="/credit" class="btn btn-primary btn-outline-white mb-2">Докладніше</router-link></a>
            </p>
        </div>
      </div>
    </div>
  </div>

  <div id="about_section" v-if="$route.name == 'MainButtons'">
    <transition-group  tag="span" name="fadeEffect" mode="in-out" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div id="about_exchange" v-show="exchangeHover && isVisible == true" :key="exchangeHover">
        <div class="container-fluid padding_about">
          <div class="row text-center">
            <div class="col-sm">
              <div class="row">
                <div class="col">
                  <img :src="require('images/if_rate.534dd39a.svg')" oncontextmenu="return false" class="icon-image"/>
                </div>
                <div class="col">
                  <p class="text-black" style="font-size: 12px">
                    Наші менеджери уважно стежать за коливанням курсу валют і надають змогу продати та/або купити долар США, євро, фунт, рубль або злотий на вигідних для клієнта умовах. Користування пунктом обміну Pokrovsk Exchange - це максимальна економія на конвертації валюти.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm">
                <div class="row">
                  <div class="col">
                    <img :src="require('images/if_24_7.21e998d8.svg')" oncontextmenu="return false" class="icon-image"/>
                  </div>
                  <div class="col">
                    <p class="text-black" style="font-size: 12px">
                      Зарезервувати необхідну суму для обміну дуже просто: необхідно надати онлайн заявку на сайті або за допомогою Telegram-бота, і протягом декількох хвилин з вами зв'яжеться менеджер для уточнення деталей. Послуга обміну доступна 24/7.
                    </p>
                  </div>
                </div>
            </div>
            <div class="col-sm">
              <div class="row">
                <div class="col">
                  <img :src="require('images/if_safe.2676a110.svg')" oncontextmenu="return false" class="icon-image"/>
                </div>
                <div class="col">
                  <p class="text-black" style="font-size: 12px">
                    Pokrovsk Exchange - один з небагатьох надійних, сучасно обладнаних  сертифікованих пунктів обміну в Покровську з бездоганною репутацією. Також, для постійних та оптових клієнтів діють знижки.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>

    <!-- <transition-group  tag="span" name="creditEffect" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div id="about_credit" v-show="creditHover && isVisible == true" :key="creditHover">
        <div class="container-fluid padding_about">
          <div class="row align-items-center text-center">
            <div class="col-sm">
              <div class="row">
                <div class="col">
                  <img :src="require('images/if_shop.663b64b2.svg')" oncontextmenu="return false" class="icon-image"/>
                </div>
                <div class="col">
                  <p class="text-black" style="font-size: 12px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="row">
                <div class="col">
                  <img :src="require('images/if_wallet.3241a78f.svg')" oncontextmenu="return false" class="icon-image"/>
                </div>
                <div class="col">
                  <p class="text-black" style="font-size: 12px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="row">
                <div class="col">
                  <img :src="require('images/if_time_money.efe13f0d.svg')" oncontextmenu="return false" class="icon-image"/>
                </div>
                <div class="col">
                  <p class="text-black" style="font-size: 12px">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group> -->
  </div>
</section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'MainButtons',
    data () {
      return {
        isVisible: true,
        creditHover: false,
        exchangeHover: true,
      }
    },
    methods: {
      setVisible () {
        $route.name == 'MainButtons' ? this.isVisible = true : this.isVisible = false
      }
    }
  }
</script>

<style scoped>

.my-trans {
  animation-duration: 0.5s;
  animation-delay: 0s;
}

.text-black {
  color: black;
}

.table {
  text-align: center;
}

.actual_rate_mobile {
  font-size: 18px;
  letter-spacing: 4.3px;
  font-weight: 600;
  text-align: center;
}

#robo {
  height: 65px;
  width: auto;
  text-align: center;
}

.sub_mobile {
  font-size: 14px;
}

#about_section {
  padding-right: 50px;
}

.font-weight-normal {
  color: black !important;
}

.fade-enter-active, .fade-leave-active {
  transition-timing-function: ease-in;
  transition-property: opacity;
  transition-duration: .50s;
}

.button-main-color:hover {
  background-color: rgb(213, 25, 32)!important;
}

.button-main-color {
  color: white!important;
}


.icon-image {
  height: 125px
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
