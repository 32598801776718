<template>
  <div>
    <div id="mobile" class="container">
      <table class="table table-bordered">
        <thead style="display: none;">
          <th>Валюта</th>
          <th>Купівля</th>
          <th>Продаж</th>
        </thead>
        <tbody>
          <tr v-for="currency in currencies" :value="currency.value" :key="currency.key">
            <td scope="row"><img class="flag-icon" :src="require(`images/${currency.flag}.svg`)"> {{ currency.name }}</td>
            <td>{{ currency.buy }}</td>
            <td>{{ currency.sell }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex-container">
        <div id="phone" v-if="active_tab == 1">
          <div class="phone_info">
            <p class="lagre_screen">Наші менеджери працють цілодобово 24/7</p>
            <p><a href="tel:+38 (066) 346 04 98">+38 (066) 346 04 98</a></p>
            <p><a href="tel:+38 (050) 580 57 55">+38 (050) 580 57 55</a></p>
            <p class="lagre_screen">Ми є у <a href="https://invite.viber.com/?g2=AQAXQqgFOkFSjknetIhPO2VytN4N9MeHDFHFsywSDIm88uCnCv127Tu18HqY17YD" style="color: #665CAC;"><font-awesome-icon :icon="['fab', 'viber']" />&nbsp;Viber</a> та <a href="https://t.me/ExchangePokrovskBot"><font-awesome-icon :icon="['fab', 'telegram']" />&nbsp;Telegram
              </a></p>
          </div>
        </div>

        <div id="map_mobile" v-if="active_tab == 2">
          <p id="city">м. Покровськ</p>
          <div class="space-evenly">
            <p @click="primaryMap = true" :class="{'bold': primaryMap}">вул. Центральна, 137-А</p>
            <p @click="primaryMap = false" :class="{'bold': !primaryMap}" id="red">вул. Європейська</p>
          </div>
          <div>
            <div class="map_info" v-if="primaryMap">
              <p>Ми знаходимость в центрі міста, біля кінцевої зупинки 🚌108-го муршрутного таксі.</p>
              <button class="fancy_button">
                <a href="https://www.google.com/maps/dir/?api=1&destination=48.282274,37.178301">Прокласти маршрут</a>
              </button>
            </div>

            <div class="map_info" v-if="!primaryMap">
              <p>Ми знаходимось в універмазі Покровськ, вхід зі сторони ринку</p>
              <button class="fancy_button insta_colors">
                <a href="https://www.google.com/maps/dir/?api=1&destination=48.275414,37.175812" id="insta_color">
                  Прокласти маршрут
                </a>
              </button>
            </div>
          </div>
        </div>

        <div id="telegram" v-if="active_tab == 3">
          <div class="telegram_main">
            <div>
              <img :src="require('images/robo.png')" id="robo">
            </div>
            <div class="telegram-info">
              <p class="telegram_sub">
                Скористуйтесь нашим Telegram-Ботом для📈автоматичного отримання курсу валют.
              </p>
            </div>
          </div>
          <div class="subscribe" style="display: inline;">
            <p>🤳Підписуйся на Telegram!</p>
          </div>
          <div class="telegram_button telegram_button_extra">
            <button class="fancy_button">
              <a href="https://t.me/ExchangePokrovskBot">
                <font-awesome-icon :icon="['fab', 'telegram']" size="lg"/>&nbsp;Відкрити в Telegram
              </a>
            </button>
          </div>
        </div>

        <div id="instagram" v-if="active_tab == 4">
          <div class="subscribe" style="display: inline;">
            <p>🤳Підписуйся на Instagram!</p>
          </div>
          <div class="telegram_button telegram_button_extra" id="instagram_button_extra">
            <button class="fancy_button" id="instagram_button">
              <a href="https://instagram.com/exchange.pokrovsk" style="color: #fb3958!important">
                <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>&nbsp;exchange.pokrovsk
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>

    <footer id="footer" class="probootstrap-navabr">
      <ul class="nav float-left" id="address_list">
        <li class="nav-item one" @click="setActive(1)">
          <a href="#"><img :src="require('images/iconfinder_Phone_2744956.svg')"></a>
        </li>
        <li class="nav-item two" @click="setActive(2)">
          <a href="#"><img :src="require('images/if_location.88dec68f.svg')"></a>
        </li>
        <li class="nav-item three" @click="setActive(3)">
          <a href="#"><img :src="require('images/telegram-plane-brands.svg')"></a>
        </li>
        <li class="nav-item four" @click="setActive(4)">
          <a href="#"><img :src="require('images/instagram-brands.svg')"></a>
        </li>
        <hr v-bind:style="{ 'margin-left': savePosition() }"/>
      </ul>
    </footer>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'MobileFooter',
    props: ['currencies'],
    data () {
      return {
        active_tab: 1,
        line_position: '0%',
        primaryMap: true
      }
    },
    methods: {
      savePosition () {
        switch (this.active_tab) {
          case 1:
            return '0%'
            break;
          case 2:
            return '25%'
            break;
          case 3:
            return '50%'
            break;
          case 4:
            return '75%'
            break;
        }
      },
      setActive (tab_number) {
        this.active_tab = tab_number;
      },
      selectMap () {
        this.primaryMap = !this.primaryMap
      },
      handleSwipe(direction) {
        let actual_tab = this.active_tab

        if (direction == 'left') {
          actual_tab - 1 == 0 ? this.active_tab = 1 : this.active_tab = (actual_tab - 1)
        }
        else {
          actual_tab + 1 >= 5 ? this.active_tab = 4 : this.active_tab = (actual_tab + 1)
        }
      }
    }
  }
</script>

<style type="text/css" scoped>
  #footer {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 5px;
    display: flex;
    justify-content: center;
  }

  #city {
    font-weight: 800;
    font-size: 18px;
  }

  .flag-icon {
    height: 15px;
    width: 15px;
  }

  .fancy_button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #3498db;
    border-radius: 0.6em;
    color: #3498db;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0px;
    padding: 1em 2em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

    
  .fancy_button:focus {
    color: #fff;
    outline: 0;
  }

  .fancy_button:hover {
    color: #fff;
    outline: 0;
  }

  .bold {
    font-size: 16px;
    font-weight: 600;
    color: #3498db;
  }

  .bold#red {
    color: #fb3958;
  }

  #map_mobile {
    text-align: center;
  }

  .space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  .insta_colors {
    border-color: #fb3958!important;
  }

  #insta_color {
    color: #fb3958!important;
  }


  #instagram {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #instagram_button {
    border: 2px solid #fb3958;
    color: #fb3958;
  }

  .fancy_button #instagram_button a {
    color: #fb3958!important;
  }

  #instagram_button_extra {
    border-color: #fb3958;
  }

  #insta_image {
    height: 200px;
    width: auto;
  }

  .map_info {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #img_container_insta {
    display: flex;
    justify-content: center;
  }

  .subscribe {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }

  .telegram_button {
    display: flex;
    justify-content: center;
  }

  .telegram-info {
    margin-bottom: 5px;
  }

  .telegram_button_extra {
    border-color: #3498db;
    color: #fff;
    transition: all 150ms ease-in-out;
  }

  .fancy_button:focus {
    outline: 0;
  }

  .fancy_button a {
    color: #3498db;
  }

  .phone_info {
    text-align: center;
  }

  hr {
    height: .15rem;
    width: 25%;
    margin: 0;
    margin-top: 0.5rem;
    background: tomato;
    border: none;
    transition: .3s ease-in-out;
  }

  .active {
    display: block;
  }

  .telegram_main {
    margin-top: 75px;
    display: flex;
  }

  a:hover {
    text-decoration: none
  }

  .two:hover ~ hr {
    margin-left: 25%;
  }

  .three:hover ~ hr {
    margin-left: 50%;
  }

  .four:hover ~ hr {
    margin-left: 75%;
  }

  #footer img {
    height: 30px;
    width: 30px;
  }

  .subscribe {
    display: none;
  }

  @media screen and (max-width: 320px) and (max-height: 568px) {
    #footer img {
      height: 25px;
      width: 25px;
    }

    #embeded_map {
      display: none!important;
    }

    .subscribe {
      display: inline;
    }

    #city, .telegram-info, .telegram_main {
      display: none;
    }

    #mobile {
      padding-top: 0px!important;
    }

    .map_info p {
      display: none;
    }

    .phone_info .lagre_screen {
      display: none;
    }
  }

  @media screen and (max-height: 650px) {
    #mobile {
      padding-top: 0px!important;
    }

    .map_info p {
      display: none;
    }

    .phone_info .lagre_screen {
      display: none;
    }

    .phone_info a {
      font-size: 15px;
    }
  }

  @media screen and (max-height: 670px) {
    .telegram_main {
      margin-top: 0px;
    }

    #embeded_map {
      max-height: 35px;
      display: none;
    }

    #city, .telegram-info, .telegram_main {
      display: none;
    }

    .map_info p {
      display: none;
    }
  }

  @media screen and (max-height: 570px) {
    #mobile {
      font-size: 12px;
    }

    .fancy_button {
      padding: 0.6em 1em!important;
    }

    #insta_image {
      height: 110px;
    }

    #city, .telegram-info, .telegram_main {
      display: none;
    }

    #telegram .subscribe {
      display: none;
    }

    #mobile {
      padding-top: 0px!important;
    }

    .map_info p {
      display: none;
    }
  }

  @media screen and (min-height: 735px) {
    #instagram, .telegram_main {
      margin-top: 75px;
    }

    #embeded_map {
      max-height: 65px;
    }

    #phone {
      margin-top: 75px;
      font-size: 18px;
    }

    #telegram .subscribe {
      display: none;
    }
  }

  @media screen and (min-height: 800px) {
    #instagram, .telegram_main {
      margin-top: 95px;
    }

    #embeded_map {
      max-height: 85px;
    }

    #phone {
      margin-top: 95px;
      font-size: 18px;
    }

    #telegram .subscribe {
      display: none;
    }
  }


  ul li {
    margin: 0;
    padding: 0px 20px 0px 20px;
  }

  #footer .container-fluid {
    height: 65px;
  }

  .table {
    text-align: center;
  }

  table tr:hover {
    background-color:rgba(204, 189, 189, .35);
  }

  .actual_rate_mobile {
    font-size: 18px;
    letter-spacing: 4.3px;
    font-weight: 600;
    text-align: center;
  }

  #robo {
    max-height: 65px;
  }

  .telegram_sub {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5px;
    text-align: center;
  }
</style>
