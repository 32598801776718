import Vue from 'vue'
import Router from 'vue-router'
import Credit from './Credit.vue'
import Exchange from './Exchange.vue'
import MainButtons from './MainButtons.vue'
import CurrencyRate from './CurrencyRate.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '/',
      name: 'MainButtons',
      components: { MainButtons, CurrencyRate }
    },
    {
      path: '/exchange',
      name: 'exchange',
      component: Exchange,
      meta: { isVisible: false, exchangeVisible: true }
    },
    {
      path: '/credit',
      name: 'credit',
      component: Credit,
      meta: { isVisible: false, CreditVisible: true }
    }
  ]
})
