<template>
<header>
  <nav class="navbar navbar-expand-sm navbar probootstrap-navabr" id="customNav">
    <div class="container-fluid" id="flex-center">
      <a class="navbar-brand" href="/"><img :src="require('images/logo.png')" oncontextmenu="return false" id="logo"/></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#probootstrap-nav" aria-controls="probootstrap-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="probootstrap-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item probootstrap-cta probootstrap-seperator currency-representation" v-for="currency in currencies" :value="currency.value" :key="currency.key">
            <div style="display: block; margin-right: 3px;">
              <span>
                <img class="flag-icon" :src="require(`images/${currency.flag}.svg`)"> {{currency.name_ukr}}
                <i class="fas fa-angle-up"></i>
                {{ currency.buy }} / {{ currency.sell }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
</template>

<script>

export default {
  name: 'CurrencyRate',
  props: ['currencies']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #customNav {
    padding: 1rem .1rem !important;
    background-color: rgba(255, 255, 255, 0.5)
  }

  .flag-icon {
    height: 25px;
    width: 25px;
  }

  #logo {
    height: 105px;
    width: auto;
    padding-top: 10px;
  }

  .icon-image img {
    width: 30px;
    height: 30px;
    margin-top: 1em;
  }

  #probootstrap-nav .nav-link {
    margin-bottom: 0px;
    color: black;
  }

  .navbar-brand {
    color: black;
  }

  #probootstrap-nav .nav-link:hover {
    color: rgba(102, 99, 99, 0.9) !important;
  }

  #customNav .container-fluid {
    height: 65px
  }

  header {
    line-height: 1.3;
  }

  .currency-representation {
    margin-right: 20px;
  }

  @media only screen and (max-device-width: 480px) {
    #logo {
      width: 100%!important;
      padding-top: 2.5%!important;
      padding-left: 2.5%!important;
      padding-right: 2.5%!important;
    }
  }

  @media (min-width: 1280px) { 
    .currency-representation {margin-right: 3px}
  }

  @media (min-width: 1400px) { 
    .currency-representation {margin-right: 20px}
  }
</style>
