<template>
<section>
  <div class="container-fluid">
    <div class="row bgGray">
      <div class="col-md-6" id="leftBordered">
        <div class="row map_box" v-if="toggled">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2655.0698896527015!2d37.17611111570658!3d48.282271279235125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40de37f15ad6d201%3A0x81fadebae39ee5bd!2sPokrovsk+Exchange!5e0!3m2!1sen!2sua!4v1548500964986" width="100%" height="250" frameborder="0" style="margin: 5px 5px 5px 5px, border:0">
          </iframe>
          <div style="display: flex; justify-content: center; width: 100%;">
            <p class="font-style text-center">
              👆Ми знаходимость в центрі міста, біля кінцевої зупинки 🚌108-го муршрутного таксі.
            </p>
          </div>
        </div>
        <div class="row map_box" v-else>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d581.9332416433272!2d37.1755130026595!3d48.276053077815845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40de39845776e7d7%3A0xbb0f459611949645!2z0J7QsdC80ZbQvSDQstCw0LvRjtGCINCf0L7QutGA0L7QstGB0YzQuiB8IGV4Y2hhbmdlLmRuLnVhIHwgQ3VycmVuY3kgRXhjaGFuZ2U!5e0!3m2!1sru!2sua!4v1567178605796!5m2!1sru!2sua&zoom=100" width="100%" height="250" frameborder="0" style="margin: 5px 5px 5px 5px, border:0"></iframe>
          <div style="display: flex; justify-content: center; width: 100%;">
            <p class="font-style text-center">👆Ми знаходимось в універмазі 'Покровськ',  вхід зі сторони ринку</p>
          </div>
        </div>
        <div class="row" style="display: flex; justify-content: center;">
          <div class="text-elements-switch"><p>Ул. Центральная</p></div>
          <div class="switch-itself"><toggle-button @change="toggleMaps()" 
                                                    v-bind:width=60
                                                    v-bind:height=28
                                                    style="margin-bottom: 20px"
                                                    v-bind:color="{'checked': '#D51920', 'unchecked': '#3498db'}"/></div>
          <div class="text-elements-switch"><p>Ул. Европейская</p></div>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <div class="row">
          <div style="margin: 5px 5px 5px 5px">
            <p class="font-style" v-for="text in texts" :key="text.body">{{ text.body }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Credit',
  data () {
    return {
      size: 'normal',
      show: true,
      texts: [{body: "Good one"},{body: "Nice"}],
      toggled: true
    }
  },
  methods: {
    getDescriptions() {
      axios.get("/descriptions?location=main")
      .then(
        (response) => { this.texts = response.data.data.map(x => x["attributes"]); },
        (error) => { console.log(error); }
      )
    },
    toggleMaps() {
      this.toggled = !this.toggled
    }
  },
  beforeMount() { this.getDescriptions() }
}
</script>


<style lang="scss">
.form-control {
  height: auto !important;
}

.map_box {
  padding-top: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
}

.text-elements-switch {
  padding-top: 25px;
}

.switch-itself {
  margin-left: 10px;
  margin-right: 10px;
}

#leftBordered {
  border-right: 1px solid black;
}

.bgGray {
  background-color: transparent;
}

.form-btn {
  padding: 10px 15px !important;
}

.font-style {
  font-size: 15px;
  color: auto;
}

.form-wrapper {
  background-color: rgba(0,0,0,0.35);
  padding: 1em 1em 1em 1em;
  border-radius: 0.5em;
  color: white !important;
}

.white-text {
  color: white !important;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 30px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control-placeholder-2 {
  position: absolute;
  top: 0;
  padding: 120px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus {
  border: 1px solid white !important;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.form-control:focus + .form-control-placeholder-2,
.form-control:valid + .form-control-placeholder-2 {
  font-size: 75%;
  transform: translate3d(0, -27%, 0);
  opacity: 1;
}

.form-control:focus {
  background: transparent;
  color: white;
}

.form-row {
  margin-top: 20px;
}

.form-group {
  padding-bottom: 20px;
}


.form-input {
  background: transparent;
}

.form-select {
  background: transparent;
  color: white;
}

option {
  color: black;
}

.form-input:not(:focus) {
  color: white;
}

#foods {
  background: transparent;
}

#creditForm {
  width: 100%;
  left: 50%;
}

.slider {
  width: 100%;
}

</style>
